import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { gmailApi } from '../api/index.js';
import { constants } from '../constants/index.js';

const USER_ID = constants.USER_ID;

export const LabelsListButtons = ({
  labelId,
  setLabelId,
  setLabelName,
  labelsList,
  setMessages,
  setCsvData,
  isLoadingGmailApi,
  setIsLoadingGmailApi,
}) => {
  const handleLoadMessages = async (id, name) => {
    setLabelId(id);
    setLabelName(name);
    await showMassagesWithLabelId(undefined, [id]);
  };

  const showMassagesWithLabelId = async (userId = USER_ID, labelIds = [labelId]) => {
    setIsLoadingGmailApi(true);
    const massagesWithLabelId = await gmailApi.getAllMessages(userId, labelIds);
    // console.log('massagesWithLabelId ', massagesWithLabelId)
    setCsvData(massagesWithLabelId);
    setMessages(massagesWithLabelId);
    setIsLoadingGmailApi(false);
  };

  return (
    <>
      <strong>Загрузка писем по ярлыку:</strong>
      {labelsList.map((lbl) => {
        return (
          <Button
            key={lbl.name + '-' + lbl.id}
            sx={{ width: '100%', justifyContent: 'space-between', textTransform: 'capitalize' }}
            onClick={() => handleLoadMessages(lbl.id, lbl.name)}
            disabled={isLoadingGmailApi}
          >
            {lbl.name}
            <small>
              ({lbl.messagesUnread ? lbl.messagesUnread : 0}/{lbl.messagesTotal ? lbl.messagesTotal : 0})
            </small>
          </Button>
        );
      })}
    </>
  );
};
