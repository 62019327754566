import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const Loader = () => {
  return (
    <>
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          zIndex: '2',
          transform: 'translate(50, 50%)',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          right: '0',
          bottom: '0',
          left: '0',
          zIndex: '1',
          backgroundColor: 'rgba(0,0,0,0.1)',
        }}
      />
    </>
  );
};
