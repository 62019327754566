import React, { useState } from 'react';
import { ConnectGmailApi } from '../Components/ConnectGmailApi';
import { Profile } from '../Components/Profile';
import { LabelsList } from '../Components/LabelsList';
import { Messages } from '../Components/Messages';
import { ExportCSV } from '../Components/ExportCSV';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
// import { Form } from '../Components/Form';
import { constants } from '../constants/';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const profileState = {
  emailAddress: '',
  messagesTotal: null,
  threadsTotal: null,
  historyId: '',
};

const defaultLabelId = 'UNREAD';

const initOptionsState = {
  apiKey: constants.CLIENT_SECRET,
  clientId: constants.CLIENT_ID,
  discoveryDocs: constants.DISCOVERY_DOCS,
  scope: constants.SCOPES,
};

export const MainPage = () => {
  const [initOptions, setInitOptions] = useState(initOptionsState);
  const [isLoadingGmailApi, setIsLoadingGmailApi] = useState(false);
  const [profile, setProfile] = useState(profileState);
  const [labelsList, setLabelsList] = useState([]);
  const [labelId, setLabelId] = useState(defaultLabelId);
  const [labelName, setLabelName] = useState(defaultLabelId);
  const [messages, setMessages] = useState([]);
  const [csvData, setCsvData] = useState([]);

  return (
    <Box sx={{ flexGrow: 1, height: '100%' }}>
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid xs={4} md={2}>
          <Item sx={{ height: '100%' }}>
            {/*<small>Данные для GmailApi:</small>*/}
            {/*<Form initOptions={initOptions} setInitOptions={setInitOptions} />*/}
            {/*<hr />*/}
            <ConnectGmailApi
              initOptions={initOptions}
              profile={profile}
              setProfile={setProfile}
              setLabelsList={setLabelsList}
              isLoadingGmailApi={isLoadingGmailApi}
              setIsLoadingGmailApi={setIsLoadingGmailApi}
            />
            <br />
            <br />
            <Profile profile={profile} />
            <br />
            <LabelsList
              labelId={labelId}
              setLabelId={setLabelId}
              defaultLabelId={defaultLabelId}
              labelName={labelName}
              setLabelName={setLabelName}
              labelsList={labelsList}
              setCsvData={setCsvData}
              setMessages={setMessages}
              isLoadingGmailApi={isLoadingGmailApi}
              setIsLoadingGmailApi={setIsLoadingGmailApi}
            />
          </Item>
        </Grid>
        <Grid xs={8} md={10}>
          <Item sx={{ height: '100%' }}>
            {profile.emailAddress && (
              <Messages
                labelId={labelId}
                labelName={labelName}
                messages={messages}
                setMessages={setMessages}
                csvData={csvData}
                setCsvData={setCsvData}
                isLoadingGmailApi={isLoadingGmailApi}
                setIsLoadingGmailApi={setIsLoadingGmailApi}
              />
            )}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};
