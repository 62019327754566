import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const MessagesTableBodyRow = ({ message }) => {
  return (
    <TableRow>
      {Object.entries(message).map(([key, value], index) => {
        return (
          <TableCell align="left" size="small" key={key + index}>
            {message[key]}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
