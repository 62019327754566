import React from 'react';
import { gapi } from 'gapi-script';
import { gmailApi } from '../api/index.js';
import { constants } from '../constants';
import Button from '@mui/material/Button';

const USER_ID = constants.USER_ID;

export const ConnectGmailApi = ({
  initOptions,
  setProfile,
  setLabelsList,
  isLoadingGmailApi,
  setIsLoadingGmailApi,
}) => {
  /**
   *  Sign in the user upon button click.
   */
  const handleAuthClick = (event) => {
    gapi.auth2.getAuthInstance().signIn();
  };

  /**
   *  Sign out the user upon button click.
   */
  // const handleSignOutClick = (event) => {
  //     gapi.auth2.getAuthInstance().signOut();
  // };

  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
    setIsLoadingGmailApi(true);
    gapi.client.init(initOptions).then(async () => {
      // Listen for sign-in state changes.
      gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
      // Handle the initial sign-in state.
      updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      setIsLoadingGmailApi(false);
    });
  };

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      loadGmailApi();
    } else {
      handleAuthClick();
    }
  };

  const loadGmailApi = () => {
    gapi.client.load('gmail', 'v1', displayProfile);
    gapi.client.load('gmail', 'v1', displayLabels);
  };

  const displayProfile = async (userId = USER_ID) => {
    const profile = await gmailApi.getProfile(userId);
    // console.log('showProfile ', profile)
    setProfile({
      ...profile,
      emailAddress: profile.emailAddress,
      messagesTotal: profile.messagesTotal,
      threadsTotal: profile.threadsTotal,
      historyId: profile.historyId,
    });
  };

  const displayLabels = async () => {
    const allLabelsGetLabel = await gmailApi.getAllLabelsGetLabel();
    setLabelsList(allLabelsGetLabel);
  };

  const handleClientLoad = () => {
    gapi.load('client:auth2', initClient);
  };

  return (
    <>
      {/*<button onClick={() => handleSignOutClick()} >Gmail Api Disconnect</button>*/}
      <Button sx={{ mt: 1, ml: 1 }} variant="outlined" onClick={() => handleClientLoad()} disabled={isLoadingGmailApi}>
        Подключиться к GmailApi
      </Button>
    </>
  );
};
