import React from 'react';

export const Profile = ({ profile }) => {
  return (
    <div>
      <strong>Профиль:</strong>
      <br />
      {profile.emailAddress || 'none'} ({profile.messagesTotal || '0'} mes)
    </div>
  );
};
