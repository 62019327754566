import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const MessagesTableHeadRow = ({ message }) => {
  return (
    <TableRow>
      {Object.entries(message).map(([key, value]) => {
        return (
          <TableCell align="left" size="small" sx={{ fontWeight: 'bold' }} key={key}>
            {key}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
