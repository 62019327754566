export const convertDate = (str) => {
  const date = new Date(str);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), month, day].join('-');
};

export const convertDateToMonth = (str) => {
  const date = new Date(str);
  const month = date.getMonth();
  const monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];
  return monthNames[month];
};

export const extractEmails = (str) => {
  // return str.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  // return str.match(
  //   /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
  // );
  const strPattern = ['([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\\.[a-zA-Z0-9._-]+)'];
  for (let i = 0; i < 1; i++) {
    const emails = [];
    const pattern = new RegExp(strPattern[i], 'g');
    let matches;
    while ((matches = pattern.exec(str)) !== null) {
      emails.push(matches[0]);
    }
    return emails.join(',');
  }
};

export const formatMessage = (message) => {
  let fMessage = {};
  let headersObj = {
    id: '',
    historyId: '',
    To: '',
    From: '',
    Month: '',
    Date: '',
    Subject: '',
    snippet: '',
  };

  message.payload.headers.map((header) => {
    const headerName =
      header.name === 'To' || header.name === 'From' || header.name === 'Date' || header.name === 'Subject';
    if (headerName) {
      headersObj[header.name] = header.value;
    }
    return headersObj;
  });

  // fMessage.id = message.id;
  // fMessage.historyId = message.historyId;
  fMessage.to = extractEmails(headersObj.To);
  fMessage.from = extractEmails(headersObj.From);
  fMessage.month = convertDateToMonth(headersObj.Date);
  fMessage.date = convertDate(headersObj.Date);
  fMessage.title = headersObj.Subject;
  fMessage.body = message.snippet;

  return fMessage;
};
