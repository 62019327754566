import { gapi } from 'gapi-script';
import { constants } from '../constants/index.js';
import { formatMessage } from '../helpers/index.js';

const USER_ID = constants.USER_ID;

class Api {
  getProfile = (userId = USER_ID) => {
    return gapi.client.gmail.users
      .getProfile({
        userId: userId,
      })
      .then(function (response) {
        const res = JSON.parse(response.body);
        // console.log('getProfile ', res)
        return res;
      });
  };

  getLabelsList = async (userId = USER_ID) => {
    const response = await gapi.client.gmail.users.labels.list({
      userId: userId,
    });
    const res = JSON.parse(response.body);
    // console.log('getLabelsList ', res.labels);
    return res.labels;
  };

  getLabelsGetLabel = async (userId = USER_ID, labelId) => {
    const response = await gapi.client.gmail.users.labels.get({
      userId: userId,
      id: labelId,
    });
    const res = JSON.parse(response.body);
    // console.log('getLabelsGetLabel ', res);
    return res;
  };

  getAllLabelsGetLabel = async (userId = USER_ID, labelIds = []) => {
    const labelList = await gmailApi.getLabelsList(USER_ID);
    const labelType = labelList.filter((lbl) => {
      if (lbl.type === 'user') {
        return lbl;
      }
    });
    // const labelType = [...labelList];

    const labelListFullInfo = [];
    for (const label of labelType) {
      const labelFullInfo = await gmailApi.getLabelsGetLabel(USER_ID, label.id);
      labelListFullInfo.push(labelFullInfo);
    }
    return labelListFullInfo;
  };

  getMessagesList = (userId = USER_ID, labelIDs = []) => {
    return gapi.client.gmail.users.messages
      .list({
        userId: userId,
        labelIds: labelIDs,
        maxResults: 100,
      })
      .then((response) => {
        const res = JSON.parse(response.body);
        // console.log('getMessagesList ', res.messages)
        return res.messages;
      });
  };

  getMessage = async (mesId, userId = USER_ID) => {
    const response = await gapi.client.gmail.users.messages.get({
      userId: userId,
      id: mesId,
    });
    const res = JSON.parse(response.body);
    // console.log('getMessage ', res)
    return res;
  };

  getAllMessages = async (userId = USER_ID, labelIds = []) => {
    const allMessages = [];
    const messagesList = await gmailApi.getMessagesList(userId, labelIds);

    for (const mes of messagesList) {
      const oneMess = await gmailApi.getMessage(mes.id);
      const formatMess = formatMessage(oneMess);
      allMessages.push(formatMess);
    }
    return allMessages;
  };
}

export const gmailApi = new Api();
