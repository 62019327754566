const CLIENT_ID = process.env.REACT_APP_WIGOS_GMAIL_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_WIGOS_GMAIL_CLIENT_SECRET;
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest'];
const SCOPES = 'https://www.googleapis.com/auth/gmail.readonly';
const USER_ID = process.env.REACT_APP_WIGOS_GMAIL_USER_ID;

export const constants = {
  CLIENT_ID,
  CLIENT_SECRET,
  DISCOVERY_DOCS,
  SCOPES,
  USER_ID,
};
