import React from 'react';
import { MessagesTable } from './MessagesTable';
import Grid from '@mui/material/Unstable_Grid2';
import { ExportCSV } from './ExportCSV.jsx';
import { Loader } from './Loader.jsx';

export const Messages = ({ labelName, messages, csvData, isLoadingGmailApi }) => {
  return (
    <>
      {isLoadingGmailApi ? (
        <Loader />
      ) : (
        <>
          {csvData && csvData.length && (
            <Grid xs={12}>
              <strong>Письма с ярлыком {labelName} загружены. </strong>
              <ExportCSV csvData={csvData} fileName={labelName} />
            </Grid>
          )}
          <Grid xs={12}>
            <MessagesTable messages={messages} />
          </Grid>
        </>
      )}
    </>
  );
};
