import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import { MessagesTableBodyRow } from './MessagesTableBodyRow';
import { MessagesTableHeadRow } from './MessagesTableHeadRow';

export const MessagesTable = ({ messages }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>{messages[0] && <MessagesTableHeadRow message={messages[0]} />}</TableHead>
        <TableBody>
          {messages.map((message, index) => {
            return <MessagesTableBodyRow key={'body-' + message.id + index} message={message} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
