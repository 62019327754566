import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const LabelsListOptions = ({ labelId, setLabelId, defaultLabelId, labelName, setLabelName, labelsList }) => {
  const handleChange = (event) => {
    // const handleChange = (event: SelectChangeEvent) => {
    const optionId = event.target.value;
    let optionName = '';
    labelsList.forEach((label) => {
      if (label.id === optionId) {
        optionName = label.name;
      }
    });

    setLabelId(optionId);
    setLabelName(optionName);
    // setLabelName(event.target.options[event.target.selectedIndex].text);
  };

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <InputLabel id="label-id-label">Выбор ярлыка</InputLabel>
        <Select labelId="label-id-label" id="label-id" value={labelId} label="Выбор ярлыка" onChange={handleChange}>
          <MenuItem value={defaultLabelId}>
            <em>{labelName}</em>
          </MenuItem>
          {labelsList.map((lbl) => {
            return (
              <MenuItem value={lbl.id} key={lbl.id}>
                {lbl.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};
