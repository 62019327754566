import React, { useState } from 'react';
import { LabelsListOptions } from './LabelsListOptions.jsx';
import { LabelsListButtons } from './LabelsListButtons.jsx';

export const LabelsList = ({
  labelId,
  setLabelId,
  defaultLabelId,
  labelName,
  setLabelName,
  labelsList,
  setMessages,
  setCsvData,
  isLoadingGmailApi,
  setIsLoadingGmailApi,
}) => {
  const [labelsListType, setLabelsListType] = useState('buttons');

  return (
    <>
      {labelsListType === 'buttons' ? (
        <LabelsListButtons
          labelId={labelId}
          setLabelId={setLabelId}
          setLabelName={setLabelName}
          labelsList={labelsList}
          setMessages={setMessages}
          setCsvData={setCsvData}
          isLoadingGmailApi={isLoadingGmailApi}
          setIsLoadingGmailApi={setIsLoadingGmailApi}
        />
      ) : (
        <LabelsListOptions
          labelId={labelId}
          setLabelId={setLabelId}
          defaultLabelId={defaultLabelId}
          labelName={labelName}
          setLabelName={setLabelName}
          labelsList={labelsList}
        />
      )}
    </>
  );
};
